import styled from 'styled-components';

export const ScrollButton = styled.button`
  position: fixed;
  bottom: 20px; 
  right: 20px;  
  background-color: #204dc1; 
  border: none;
  border-radius: 6px;
  padding: 10px; 
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s; 
  display: flex; 
  align-items: center; 
  justify-content: center; 

  &:hover {
    background-color: #2C73FD; 
  }
`;