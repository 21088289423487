import styled from "styled-components";

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  margin: 80px 0px 80px 0px;
  color: white;
  width: 1120px;

  @media screen and (max-width: 960px) {
    margin: 60px 20px;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 560px) {
    box-sizing: border-box;
  }
`;

export const FooterColumnOne = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 505px;
  padding-left: 20px;

  @media screen and (max-width: 960px) {
    align-items: center;
  }

  @media screen and (max-width: 560px) {
    width: 370px;
  }
`;

export const FooterColumnTwo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 960px) {
    align-items: center;
  }
`;

export const FooterColumnThree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;

  @media screen and (max-width: 960px) {
    align-items: center;
  }
`;

export const FooterColumnFour = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 960px) {
    margin: 50px 0px 0px 0px;
  }
`;

export const LargeText = styled.div`
  font-size: 14px;
  margin: 20px 0 80px 0;
  width: 458px;
  text-align: left;
  color: #FFF;
  font-family: 'Poppins', sans-serif;

  @media screen and (max-width: 960px) {
    text-align: center;
  }

  @media screen and (max-width: 560px) {
    width: 370px;
    margin: 20px 0 20px 0;
  }
`;

export const SmallText = styled.div`
  font-size: 14px;
  margin-top: auto;
  color: gray;
  text-align: left;
  width: 100%;

  @media screen and (max-width: 960px) {
    display: none;
  }

  &.HiddenFooter {
    display: none;

    @media screen and (max-width: 960px) {
      display: flex;
      width: 340px;
      text-align: center;
      margin-top: 30px;
      font-size: 12px;
    }
  }
`;

export const TextFooter = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;

  &.TextFooterStyle {
    font-size: 16px;
    font-weight: 300;
    color: gray;
    text-decoration: underline;

    @media screen and (max-width: 560px) {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 560px) {
    margin-top: 10px;
    font-size: 18px;
  }
`;

export const Icons = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;
  margin-top: auto; 
`;


export const DivTransform = styled.div`
  display: flex;
  width: 40%;
  justify-content: space-around;
  gap: 20px;

  @media screen and (max-width: 960px) {
    width: 60%;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 560px) {
    margin-top: 40px;
  }
`;
