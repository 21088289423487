import styled from 'styled-components';

export const FAQContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px 76px 20px;
  font-family: 'Poppins', sans-serif;

  h1 {
    text-align: center;
    color: #3C3C3C;
    font-size: 24px;
  }

  p {
    text-align: center;
    font-size: 16px;
    color: #6c757d;
    margin-bottom: 20px;
  }

`;

export const FAQItem = styled.div`
  margin-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
`;

interface QuestionProps {
  isOpen: boolean;
}

export const Question = styled.button<QuestionProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 800px;
  background-color: #f5f5f5;
  color: #3C3C3C;
  text-align: left;
  padding: 15px;
  border: 1px solid #ccc;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 8px 8px 0 0;
  
  &:hover {
    background-color: #f0f0f0;
  }

  &::after {
    content: '${(props) => (props.isOpen ? '-' : '+')}';
    float: right;
    font-size: 18px;
    transition: transform 0.3s ease;
    transform: rotate(${(props) => (props.isOpen ? '0deg' : '90deg')});
  }

  @media screen and (max-width: 800px) {
    min-width: 100%;
    }
`;

export const Answer = styled.div<{ isOpen: boolean }>`
  background-color: #f9f9f9;
  padding: ${(props) => (props.isOpen ? '15px' : '0')}; /* Adiciona padding apenas quando estiver aberto */
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  overflow: hidden;
  max-height: ${(props) => (props.isOpen ? '500px' : '0')}; /* Definir um valor máximo razoável para a altura */
  transition: max-height 0.5s ease, padding 0.5s ease;
  border-radius: 0 0 8px 8px;
  text-align: left;
`;
