import styled, { keyframes } from 'styled-components';
import { List, X } from '@phosphor-icons/react';

const rotateIn = keyframes`
  from {
    transform: rotate(-90deg);
    opacity: 0;
  }
  to {
    transform: rotate(0deg);
    opacity: 1;
  }
`;

const rotateOut = keyframes`
  from {
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(-90deg);
    opacity: 0;
  }
`;

export const AnimatedList = styled(List)<{ isOpen: boolean }>`
  animation: ${({ isOpen }) => (isOpen ? rotateOut : rotateIn)} 1s forwards;
`;

export const AnimatedX = styled(X)<{ isOpen: boolean }>`
  animation: ${({ isOpen }) => (isOpen ? rotateIn : rotateOut)} 1s forwards;
`;

export const DivAll = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F5F5F5;
`;

export const Nav = styled.nav`
  width: 1240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding-inline: 5px;

  @media screen and (max-width: 1260px) {
    padding-inline: 20px;
  }

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const NavHamburguer = styled.nav`
  width: 960px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding-inline: 20px;

  @media screen and (min-width: 960px) {
    display: none;
  }
`;

export const DivHamburguer = styled.nav`
  background-color: #EDECEC;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`;

export const Logo = styled.div`
  img {
    width: 64px;
    height: auto;

    @media screen and (max-width: 960px) {
      width: 57px;
      height: auto;
    }
  }
`;

export const NavLinks = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
`;

export const NavItem = styled.div`
  a {
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #323232;
    font-size: 16px;
    font-weight: 500;

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: #2C73FD;
      transition: width 0.3s ease;
    }

    &:hover::after {
      width: 100%;
    }
  }
`;

export const NavRight = styled.div`
  display: flex;
  gap: 20px;
`;

export const NavButton = styled.div`
  a {
    text-decoration: none;
    color: #323232;
    font-size: 16px;
    padding: 8px 24px;
    border-radius: 22px;
    border: 1px solid #e2e2e2;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e2e2e2;
      color: #323232;
    }
  }

  &.second {
    
    cursor: pointer;
    a {
      background-color: #2C73FD;
      color: white;
      border: 1px solid transparent;
      padding: 8-px 28px;
      transition: background-color 0.3s ease; 

      &:hover {
        background-color: #204dc1;
        transition: background-color 0.3s ease; 
      }
    }
  }
`;

export const NavHidden = styled.nav`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1000;
  background-color: white;
  border-bottom: 5px solid black;
  font-size: 16px;
  gap: 30px;
  
  div {
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: #000000;
  }
`;
