import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import YouTube from 'react-youtube';
import './styles';
import {
  AnimatedList, AnimatedX, DivAll, DivHamburguer, Logo, Nav, NavButton,
  NavHamburguer, NavHidden, NavItem, NavLinks, NavRight
} from './styles';
import LogoMarca from '../../../assets/logo2.png';
import { X, PlayCircle } from '@phosphor-icons/react';

export default function Navbar(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const videoOptions = {
    height: '315',
    width: '100%',
    playerVars: {
      autoplay: 1,                   
      modestbranding: 1,     
      showinfo: 0,           
      rel: 0,                
      disablekb: 1,          
    },
  };

  return (
    <div style={{ position: 'fixed', top: 0, zIndex: 1000, width: '100%' }}>
      <DivAll>
        <Nav>
          <Logo>
            <a href="/"><img src={LogoMarca} alt="Logo" /></a>
          </Logo>
          <NavLinks>
            <NavItem>
              <a href="#home">Home</a>
            </NavItem>
            <NavItem>
              <a href="#beneficios">Benefícios</a>
            </NavItem>
            <NavItem>
              <a href="#planos">Planos</a>
            </NavItem>
            <NavItem>
              <a href="#depoimentos">Depoimentos</a>
            </NavItem>
          </NavLinks>
          <NavRight>
            <NavButton>
              <a href="https://painel.atovenda.com.br" target="_blank" rel="noopener noreferrer">Entrar</a>
            </NavButton>
            <NavButton className="second" onClick={showModal}>
              <a>Conheça Nosso Sistema</a>
            </NavButton>
          </NavRight>
        </Nav>

        <NavHamburguer>
          <Logo>
            <img src={LogoMarca} alt="Logo" />
          </Logo>
          <NavButton className="second" onClick={showModal}>

            <a style={{display: "flex", alignItems: "center", justifyContent: "center", gap: "4px"}}><PlayCircle size={16} color="#ffffff" weight="fill"/> Demonstração</a>
          </NavButton>
          <DivHamburguer onClick={handleToggle}>
            {isOpen ? (
              <AnimatedX size={32} weight="thin" color="#2C73FD" isOpen={isOpen} />
            ) : (
              <AnimatedList size={24} color="#2C73FD" isOpen={isOpen} />
            )}
          </DivHamburguer>
        </NavHamburguer>
      </DivAll>

      {isOpen && (
        <NavHidden>
          <div> <a href="#home"> Home </a> </div>
          <div> <a href="#beneficios"> Benefícios </a> </div> 
          <div> <a href="#planos"> Planos </a> </div>
          <div> <a href="#depoimentos"> Depoimentos </a> </div>
        </NavHidden>
      )}

      <Modal
        title="Conheça a nossa plataforma"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        closeIcon={<X size={24} />}
      >
        <YouTube videoId="N_Hg8iKcSUg" opts={videoOptions} />
      </Modal>
    </div>
  );
}
