import React from 'react';
import { BotaoWhats } from './styles';
import { FaWhatsapp } from "react-icons/fa";

const WhatsAppButton: React.FC = () => {
  return (
    <BotaoWhats>
      <a 
        href="https://wa.me/554498069716?text=Gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20ATOvenda" 
        target="_blank" 
        rel="noopener noreferrer"
        className="icon-whats"
      >
        <FaWhatsapp size={32} /> 
      </a>
    </BotaoWhats>
  );
};

export default WhatsAppButton;
