import styled, { keyframes, css } from 'styled-components';

document.body.style.margin = "0";
document.body.style.padding = "0";
document.body.style.overflowX = "hidden";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const DivAll = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif; 

  .slick-prev, .slick-next {
    z-index: 1;
    margin-inline: 20px;
    display: none !important;
  }

  .slick-prev::before, .slick-next::before {
    color: #2C73FD; 
    display: none !important;
  }
`;

export const ContainerOne = styled.div`  
  display: flex;
  width: 100%;
  box-sizing: border-box;
  animation: ${fadeIn} 1s ease-in-out;

  @media screen and (max-width: 960px) {
    box-sizing: border-box;
  }
`;

export const SectionOne = styled.div`
  display: flex;
  flex-direction: row;
  gap: 110px;
  justify-content: center;
  align-items: center;
  background-color: #F5F5F5;
  width: 100%;
  height: 600px;
  margin: 130px auto 100px auto;
  border: none;
  animation: ${fadeIn} 1.5s ease-in-out;

  @media screen and (max-width: 1080px) {
    gap: 56px;
  }

  @media screen and (max-width: 960px) {
    flex-direction: column-reverse;
    height: 100%;
    padding: 0px;
    gap: 0px;
    margin: 130px auto auto auto;
  }
`;

export const Card = styled.div`
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardLeft = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 557px;
  background-color: #F5F5F5;
  box-sizing: border-box;

  @media screen and (max-width: 1260px) {
    padding-inline: 20px;
  }

  @media screen and (max-width: 960px) {
    width: 650px;
    max-width: 100%;
    padding-inline: 0px;
  }

  @media screen and (max-width: 460px) {
    width: 100%;
    padding-inline: 0px;
  }

  .seloWhatsapp {
    width: 100px;
    height: 34px;
    
  }

  
`;

export const CardRight = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #F5F5F5;
  box-sizing: border-box;
  position: relative;

 
  .logoWhatsapp {
    position: absolute;
    width: 54px;
    height: 54px;
    z-index: 1;
    bottom: 0px;
    left: 100px;
    animation: moveUpDown 2s ease-in-out infinite;

    @media screen and (max-width: 470px) {
      width: 42px;
      height: 42px;
      left: 60px;
    }
  }

  .logoTelegram {
    position: absolute;
    width: 54px;
    height: 54px;
    z-index: 1;
    top: 66px;
    right: 40px;
    animation: moveUpDown 2s ease-in-out infinite;

      @media screen and (max-width: 470px) {
        width: 42px;
        height: 42px;
        right: 23px;
      }

  }

  @keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-14px); 
    }
    100% {
      transform: translateY(0);
    }
  }


`;

export const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 10px;
  font-weight: 600;
  color: #232427;
  text-align: left;
  box-sizing: border-box;

  @media screen and (max-width: 1260px) {
    font-size: 28px;
  }

  @media screen and (max-width: 960px) {
    text-align: left; 
    font-size: 24px;
    font-weight: 600;
    margin: 60px 20px 0 20px;
  }

  span {
    color: #2C73FD;
  }
`;

export const TextOne = styled.p`
  font-size: 1rem;
  margin-bottom: 20px;
  color: #535353;
  text-align: left; 

  @media screen and (max-width: 1260px) {
    font-size: 16px;
  }

  @media screen and (max-width: 960px) {
    font-size: 16px;
    margin: 20px;
  }
`;

export const Button = styled.button`
  align-self: flex-start;
  padding: 14px 30px;
  font-size: 1rem;
  color: #fff;
  background-color: #2C73FD;
  border: none;
  border-radius: 22px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  transition: background-color 0.3s ease; 

  @media screen and (max-width: 960px) {
    height: 52px;
    margin: 0 0px 0 20px;
  }

  &:hover {
    background-color: #204dc1;
    transition: background-color 0.3s ease; 
  }

  @media screen and (max-width: 460px) {
    font-size: 16px;
  }

`;

export const Image = styled.img`
  height: 450px;
  
  @media screen and (max-width: 960px) {
    height: auto;
    width: 100%;
    max-width: 420px;
  }

  @media screen and (max-width: 470px) {
    height: auto;
    width: 100%;
    max-width: 350px;
    margin-inline: 20px;
  }

  @media screen and (max-width: 394px) {
    margin-inline: 14px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  background-color: #EDECEC;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 130px 130px 0px 0px ;
  padding-block: 54px;
  box-sizing: border-box;
  animation: ${fadeIn} 1.5s ease-in-out;

  @media screen and (max-width: 1260px) {
    max-width: 1260px;
   border-radius: 50px 50px 0 0; 
  }

  @media screen and (max-width: 960px) {
    padding-inline: 16px;
    border-radius: 0 ;
    padding: 80px 20px 80px 20px;
    margin-top: 50px;
  }
  
`;

export const SectionTwo = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  width: 1180px;

  @media screen and (max-width: 1260px) {
    justify-content: space-evenly;
    max-width: 100%;
  }

  @media screen and (max-width: 960px) {
    gap: 20px;
    flex-direction: column;
    align-items: center;
  }
`;

export const SecondCardLeft = styled.div`
  display: flex;
`;

export const FlexCheck = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0 0 8px 8px;
    color: #535353;
  }
  @media screen and (max-width: 960px) {
    margin: 0 20px;
  }

`;

export const FlexUm = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 30px 0;

  @media screen and (max-width: 460px) {
    gap: 0px;
  }
`;

export const SecondImage = styled.img`
  width: 400px;
  position: absolute;
  bottom: -54px;
  left: 26px;

  @media screen and (max-width: 1260px) {
    
  }

  @media screen and (max-width: 1080px) {
    width: 380px;
    left: 20px;
  }

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const SecondImageMob = styled.img`
  display: none;

  @media screen and (max-width: 960px) {
    display: block;
    width: 80%;
    margin: 0 auto;
    position: inherit;
  }

  @media screen and (max-width: 960px) {
    display: block;
    width: 100%;
    margin: 0 auto;
    position: inherit;
  }
`;

export const SecondCardRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 500px;

  @media screen and (max-width: 1260px) {
    max-width: 100%;
  }

  @media screen and (max-width: 1080px) {
    margin-left: 440px;
  }

  @media screen and (max-width: 960px) {
    width: 650px;
    max-width: 100%;
    margin: 0 auto;
  }
  
`;

export const SecondTitle = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0px;
  color: #232427;
  text-align: left; 

  span {
    color: #2C73FD;
  }


  @media screen and (max-width: 1260px) {
    font-size: 28px;
    margin: 0 0 0 0;
    font-weight: 600;
  }

  @media screen and (max-width: 960px) {
    font-size: 24px;
    width: 650px;
    max-width: 100%;
    padding: 20px 0;
  
  }

`;

export const SecondText = styled.p`
  font-size: 1rem;
  margin: 30px 20px 30px 0;
  color: #535353;
  text-align: left;

  @media screen and (max-width: 960px) {
    font-size: 16px;
    margin: 0px 0px 30px 0px;
  }
`;

export const SecondButton = styled.button`
  align-self: flex-start;
  padding: 14px 30px;
  font-size: 1rem;
  color: #fff;
  background-color: #2C73FD;
  border: none;
  border-radius: 22px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif; 
  transition: background-color 0.3s ease; 

 

  @media screen and (max-width: 960px) {
    width: 92%;
  }

  &:hover {
    background-color: #204dc1;
    transition: background-color 0.3s ease; 
  }

  a {
    text-decoration: none; 
    color: #ffffff;
  }

`;


//--------------------------22
export const DivMid = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #EDECEC;
  width: 100%;
  padding-bottom: 40px;

  @media screen and (max-width: 960px) {
    background-color: transparent;
    padding-bottom: 0px;
  }
`;

export const CardAuto = styled.div`
  position: relative;
  top: -24px;
  width: 900px;
  background-color: #FFF;
  /* background-color: red; */
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: 40px;
  padding-block: 40px;
  justify-content: center;
  gap: 50px;

  @media screen and (max-width: 1080px) {
    padding-inline: 20px;
  }

  @media screen and (max-width: 960px) {
    margin-bottom: 0px;
    width: 100%;
    padding-inline: 0px;
    gap: 30px;
  }

  @media screen and (max-width: 760px) {
    height: auto;
    top: -10px;
    box-sizing: border-box;
    padding-block: 0px;
    gap: 0px;
    justify-content: space-around;
    top: -54;
    flex-direction: column;
    justify-content: flex-start;
    padding: 80px 0px 0px 0px;
  }

  @media screen and (max-width: 460px) {
    top: -30px;
  }
`;

export const SectionThree = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 80px 0;
  background-color: #204DC1;
  color: #fff;
  box-sizing: border-box;

  @media screen and (max-width: 960px) {
    padding: 60px 0;
  }

`;

export const HeaderText = styled.h1`
  font-size: 2rem;
  margin-block: 0px;
  width: 900px;
  font-weight: 600;

  @media screen and (max-width: 960px) {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }

  @media screen and (max-width: 760px) {
    padding-inline: 24px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #FFF;
  border-radius: 50px;
  width: 500px;
  margin-top: 52px;
  border: none;
`;

export const CardAutoImage = styled.img`
  display: flex;
  border-radius: 20px;


  @media screen and (max-width: 760px) {
    width: 358px;
    /* height: 200px; */
  }

  @media screen and (max-width: 760px) {
    padding-left: 20px;
  }

  @media screen and (max-width: 460px) {
    padding-left: 0px;
  }
`;

export const CardAutoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 960px) {
    height: 100%;
    padding-right: 20px;
  }

  @media screen and (max-width: 760px) {
    justify-content: flex-start;
    margin-top: 40px;
  }

  @media screen and (max-width: 460px) {
    padding-right: 0px;
    max-width: 100%;
  }
`;

export const CardAutoText = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: flex-start;

  p {
    margin-block: 0px;
    margin-left: 8px;
  }

  

  @media screen and (max-width: 760px) {
    height: 100%;
    width: auto;

    p {
      margin: 0;
    }
  }
`;

export const Icon = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 10px;
`;

export const TextFour = styled.p`
  font-size: 0.95rem;
  color: #232427;
  text-align: left;
  width: 417px;
  padding-top: 10px;

  @media screen and (max-width: 960px) {
    width: 330px;
    font-size: 1rem;
  }

  @media screen and (max-width: 760px) {
    width: 350px;
    font-size: 1rem;
  }
`;

export const TextBlock = styled.p`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 760px) {
    flex-direction: column;
  }
`;

export const TitleFour = styled.p`
  font-size: 1.2rem;
  font-weight: 600;
  color: #232427;
  text-align: left;
  width: 389px;

  @media screen and (max-width: 960px) {
    color: #3C3C3C;
    width: 330px;
    font-size: 19px;
  }

  @media screen and (max-width: 760px) {
    width: 350px;
    font-size: 18px;
  }
`;

export const DivGrids = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 80px;

  p {
    font-size: 2rem;
    color: #3C3C3C;
    font-weight: 400;
    margin: 0px;
  }

  a {
    color: #2C73FD;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 52px;

  }

  @media screen and (max-width: 960px) {
    box-sizing: border-box;

    a {
      font-size: 24px;
      margin-bottom: 20px;
    }

    p {
      font-size: 24px;
    }
  }


`;

export const SectionGrids = styled.section`
  display: flex;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  padding: 20px;

  @media screen and (max-width: 1080px) {
    gap: 30px;
  }

  @media screen and (max-width: 960px) {
    flex-direction: column-reverse;
  }

  @media screen and (max-width: 560px) {
    gap: 30px;

  }
`;

export const LeftGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 567px;
  height: 277px;
  margin-top: 48px;

  @media screen and (max-width: 1260px) {
    width: auto;
    height: auto;
    margin-top: 0px;
  }

  @media screen and (max-width: 960px) {
    gap: 0px;
    width: 100%;
    height: 100%;
    margin-bottom: 60px;
  }
`;

export const RightGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 456px;
  height: 390px;
  margin: 0 auto;

  @media screen and (max-width: 960px) {
    justify-content: space-around;
  }

  @media screen and (max-width: 560px) {
    display: none;
  }
`;

export const LargeTextDiv = styled.div`
  font-size: 1.76rem;
  text-align: center;
  text-align: left;
  font-weight: 600;
  color: #3C3C3C;

  @media screen and (max-width: 960px) {
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
  }
`;

export const MediumTextDiv = styled.div`
  font-size: 1rem;
  text-align: center;
  text-align: left;
  color: #535353;

  span {
    color: #3C3C3C;
    font-weight: 600;
  }

  @media screen and (max-width: 960px) {
    font-size: 16px;
    text-align: left;
    margin-bottom: 30px;
  }
`;


export const SmallTextDiv = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

export const CardGrid = styled.div`
  border: 1px solid #ccc;
  border-radius: 50px;
  width: 216px;
  height: 148px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media screen and (max-width: 560px) {
    margin-top: 40px;
    margin-inline: auto;
    width: 280px;
    background-color: #fdfdfd;
    border-radius: 30px;
    border: none;
  }
`;

export const IconGrid = styled.div`
  position: absolute;
  top: -32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px; 
  height: 60px; 
  border-radius: 50%; 
  border: 2px solid #204DC1; 
  background-color: #204DC1;
  color: white;
`;

export const IconGridContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px; 
  height: 60px;
`;

export const CardText = styled.div`
  width: 194px;
  height: 84px;
  font-size: 14px;
  margin: 0px 20px 24px 20px;
  a{
    width: 194px;
    height: 84px;
    font-size: 14px;
    font-weight: 400;
    color: #535353;
  }
`;

export const LeftTexts = styled.div`
  a{
    font-size: 1rem;
    color: #535353;
    margin-left: 6px;
    font-weight: 500;
  }
  @media screen and (max-width: 960px) {
    margin-bottom: 10px;
  }
  &.newPadding{
    padding-left: 30px;
    @media screen and (max-width: 960px) {
      padding-left: 0px;
    }
  }
`;

export const CheckStyle = styled.div`
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #00B768; 
  text-align: center;
  line-height: 32px;
`;

export const DivClient = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  background-color: #f5f5f5;
  padding: 20px 0 160px 0;

  @media screen and (max-width: 860px) {
    padding: 20px 0 120px 0;
  }
`;

export const CustomSection = styled.section`
  width: 1204px;
`;

export const IntroDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  @media screen and (max-width: 860px) {
    margin-bottom: 10px;
  }
`;

export const TripleColumnsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 46px;
  @media screen and (max-width: 860px) {
    justify-content: center;
    align-items: center;
  }
`;

export const SingleColumn = styled.div`
  background-color: #fbfbfb;
  border: 1px solid #eee;
  height: 330px;
  width: 388px;
  border-radius: 16px;
  @media screen and (max-width: 860px) {
    margin-top: 20px;

  }
`;

export const ColumnIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

export const CenteredTextOne = styled.p`
  text-align: center;
  width: 478px;
  margin: 0px;
  padding: 0px;
  font-size: 2rem;
  font-weight: 600;
  color: #3C3C3C;
  span{
    color: #2C73FD;
    font-size: 2rem;
    font-weight: 600;
    font-weight: bold;
  }
  @media screen and (max-width: 860px) {
    font-size: 24px;
    width: 100%;
    margin-inline: 16px;
    span{
      font-size: 24px;
    }
  }
`;

export const CenteredText = styled.p`
  font-size: 14px;
  text-align: left;
  color:  #535353;
  margin: 0px 0px 55px 0px;
  @media screen and (max-width: 860px) {
    margin-bottom: 35px;
  }
`;

export const CenteredClient = styled.p`
  text-align: left;
  margin: 0px;
  &.first{
    color: #2C73FD;
    font-weight: bold;
  }
  &.second{
    color: #535353;
    font-weight: 400;
    font-size: 14px;
  }
`;

export const DualTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LeftTextGroup = styled.div`
  display: flex;
  flex-direction: column;
`;


export const ActionButtonsDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 60px;

  @media screen and (max-width: 1260px) {
    justify-content: left;
    margin-left: 80px;
  }
  @media screen and (max-width: 860px) {
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
`;

export const CustomButton = styled.button`
  padding: 16px 60px;
  background-color: #2C73FD;
  color: white;
  font-family: 'Poppins', sans-serif; 
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  cursor: pointer;
  height: 56px;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #0056b3;
  }
  &.second {
    color: #3C3C3C;
    border: 1px solid #C4C4C4;
    background-color: transparent;
    margin-left: 10px;
    
    @media screen and (max-width: 860px) {
      padding: 16px 30px;
    }

    @media screen and (max-width: 480px) {
    font-size: 14px;
  }
  }

  @media screen and (max-width: 480px) {
    font-size: 14px;
    padding: 16px 30px;
  }

`;

export const LogoEnd = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
  
  @media screen and (max-width: 860px) {
    margin: 34px 0px 25px 0px;
  }
`;

export const SectionSeven = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0px 100px 0px;
  width: 100%;
  @media screen and (max-width: 860px) {
    height: auto;
    padding: 80px 0 !important; 
  }
`;

export const DivSeven = styled.section`
  display: flex;
  justify-content: space-between;
  background-color: #EDECEC;
  border-radius: 26px;
  height: 380px;
  width: 1120px;
 
  @media screen and (max-width: 1080px) {
    margin-inline: 10px;
  }
  @media screen and (max-width: 860px) {
    flex-direction: column;
    height: 100%;
    align-items: center;
    border-radius: 16px;
    width: 400px;
  }
`;



export const ContentSeven = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 617px;
  text-align: left;
  padding-left: 64px;
  @media screen and (max-width: 1080px) {
    width: 350px;
  }
  @media screen and (max-width: 860px) {
    width: 350px;
    padding-left: 0px;
    align-items: center;
  }
`;

export const TextSeven = styled.div`
  font-size: 26px;
  font-weight: 400;
  margin: 45px 0px 30px 0px;
  color: #3C3C3C;
  span {
    color: #2C73FD;
    font-weight: bold;
  }
  @media screen and (max-width: 1080px) {
    font-size: 20px;
    text-align: center;
  }
`;

export const TextSmallSeven = styled.div`
  font-size: 1rem;
  margin-bottom: 30px;
  color: #535353;
  @media screen and (max-width: 860px) {
    font-weight: 400;
    color: #535353;
    text-align: center;
    margin: 30px 10px 30px 10px;
  }
`;

export const ButtonSeven = styled.button`
  padding: 16px 60px;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  color: white;
  background-color: #2C73FD;
  width: 357px;
  height: 56px;
  margin-bottom: 45px;
  font-family: 'Poppins', sans-serif; 
  @media screen and (max-width: 860px) {
    width: 320px;
    padding: 16px 40px;
  }
`;

export const ImageSeven = styled.img`
  margin-top: auto;
  height: 426px;
  position: relative;
  bottom: 47px;

  @media screen and (max-width: 860px) {
    display: none;
  }

  &.HiddenImage {
    @media screen and (min-width: 860px) {
      display: none;
    }
    display: flex;
    width: 270px;
    height: 309px;
    position: static;
  }
`;

export const SectionPlans = styled.section`
  background-color: #f5f5f5;
  width: 100%;
  margin: 80px 20px 0 20px;

  @media screen and (max-width: 960px) {
    margin: 20px 20px 0 20px;
  }
    
`;

export const TopDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export const BottomDiv = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 90px;
  @media screen and (max-width: 760px) {
    margin-top: 30px;
    margin-bottom: 70px;
  }
  &.CarouselTwo {
    margin-top: 0px;
    @media screen and (min-width: 1080px) {
      display: none;
    }
  }
  &.HiddenBottom {
    @media screen and (max-width: 1080px) {
      display: none;
    }
  }

`;

export const ColumnPlans = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 330px;
  border: 1px solid #ccc;
  border-radius: 40px;
  height: 750px;
  margin: 40px 10px 10px 10px;
  &.CarouselTwo {
    margin-top: 60px;
  }
  &.More {
    position: relative;
    border-radius: 0px 0px 40px 40px;
    border: 1px solid #00B768;
  }

`;

export const TextTitlePlans = styled.div`
  margin-top: 40px;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #2C73FD;
  font-family: 'Poppins', sans-serif; 
  span {
    font-size: 20px;
  }
  &.style {
    margin-inline: auto;
    font-size: 40px;
    margin-top: 14px;
    font-weight: 700;
    width: 174px;
    margin-bottom: 0px;
  }
`;

export const Value = styled.div`
  color: red;
  margin-bottom: 10px;
  color: #2C73FD;
  font-size: 0.9rem;
  margin-top: -8px;
`;

export const TextAll = styled.div`
  width: 659px;
  font-size: 2rem;
  font-weight: 600;
  a {
    color: #3C3C3C;
    font-weight: 400;
  }
  font-weight: bold;
  color: #2C73FD;
  @media screen and (max-width: 960px) {
    font-size: 24px;
    padding-inline: 16px;
    a {
      font-size: 24px;
    }
  }
`;

export const TextPlans = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  width: 300px;
  text-align: center;
  color: #535353;
  font-family: 'Inter', sans-serif;
  &.textplan {
    font-size: 12px;
    margin-inline: auto;
    font-family: 'Inter', sans-serif; 
    width: 241px;
  }
`;

export const ContentRed = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  width: 290px;
  height: 47px;
  color: #414141;
  border: none;
  border-radius: 10px;
  background-color: #F5E3F0;
  margin-top: auto;
  margin-inline: auto;
  text-align: center;
`;

export const TextDescription = styled.div`
  margin-bottom: 5px;
  width: 270px;
  margin-inline: auto;
  font-size: 1rem;
  color: #3C3C3C;
`;

export const ButtonPlans = styled.button`
  padding: 16px 6px;
  width: 289px;
  font-size: 16px;
  font-weight: 500;
  margin-inline: auto;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 32px;
  color: #FFF;
  background-color: #00B768;
  border: none;
  border-radius: 30px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #03ce77;
    transition: background-color 0.3s ease;
  }

`;

export const Label = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  background-color: #00B768;
  position: absolute;
  top: -54px;
  left: -1px;
  width: 100%;
  text-align: center;
  color: white;
  border-radius: 40px 40px 0px 0px;
  border: 1px solid transparent;
`;

//*-------------------------------

const moveBackground = (start: number, end: number) => keyframes`
  0% {
    left: ${start}%;
  }
  100% {
    left: ${end}%;
  }
`;


export const DivMove = styled.div`
  display: flex;
  position: relative;
  height: 50px;
  border-radius: 36px;
  background-color: #FFF;
  margin-top: 42px;
  
  @media screen and (max-width: 960px) {
    margin-top: 26px;
  }
  
  @media screen and (max-width: 760px) {
    width: 350px;
  }
  
  @media screen and (max-width: 460px) {
    display: none;
  }
`;

export const DivClick = styled.div`
  padding: 20px;
  text-align: center;
  color: black;
  font-size: 1rem;
  border-radius: 36px;
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  
  a {
    color: #323232;
    transition: color 0.5s ease;
    
    @media screen and (max-width: 760px) {
      font-size: 12px;
    }

    &.active {
      color: #FFF;
    }
  }
`;

export const BackgroundStyle = styled.div<{ start: number; end: number }>`
  position: absolute;
  top: 0;
  left: ${({ start }) => start}%;
  width: 33.33%;
  height: 100%;
  background-color: #2C73FD;
  border-radius: 36px;
  animation: ${({ start, end }) => css`${moveBackground(start, end)} 0.5s forwards`};
  z-index: 1;
`;

export const RightGridHidden = styled.div`
  margin-bottom: 60px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  margin-inline: auto;

  @media screen and (min-width: 560px) {
    display: none;
  }
`;

export const CarouselContainer = styled.div`
  width: 100%;

  &.CarouselTwo {
    width: 370px;
  }

  &.CarouselThree {
    @media screen and (max-width: 1260px) {
      width: 800px;
    }

    @media screen and (max-width: 760px) {
      width: 380px;
    }
  }

  &.CarouselFour {
    width: 1030px;

    @media screen and (max-width: 1060px) {
      width: 700px;
    }

    @media screen and (max-width: 760px) {
      width: 380px;
    }
  }
`;

export const Slide = styled.div`
  height: 200px;
  width: 260px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.CarouselTwo {
    height: 900px;
    display: flex !important;
  }

  &.CarouselThree {
    height: 350px;
    display: flex !important;
  }

  &.CarouselFour {
    height: 860px;
    display: flex !important;
    align-items: end;

    @media screen and (max-width: 480px) {
      height: 810px;
      margin-top: 32px;
  }
  }
`;

export const DivMargin = styled.div`
  margin: 20px 30px;
`;

export const Aspas = styled.a`
  font-family: 'Russo One', sans-serif;
  color: #2C73FD;
  font-size: 80px;
  height: 37px;
`;

export const DivChat = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 12px auto;

  @media screen and (min-width: 460px) {
    display: none;
  }
`;

export const ChatBubble = styled.div<{ clicked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${props => (props.clicked ? '#007bff' : '#FFFFFF')};
  border-radius: 24px;
  align-items: center;
  width: 156px;
  height: 60px;
  color: ${props => (props.clicked ? '#FFFFFF' : '#323232')};
  cursor: pointer;
  transition: background-color 0.3s ease;

  @media (max-width: 560px) {
    margin-bottom: 24px;
  }

  &:after {
    content: '';
    display: ${props => (props.clicked ? 'block' : 'none')};
    position: absolute;
    bottom: -16px;
    left: 66px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 10px 0 10px;
    border-color: #007bff transparent transparent transparent;
  }
`;
