import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
`;

export const BotaoWhats = styled.div`
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 100px;
  right: 45px;
  background-color: #0fbd69;
  box-shadow: 0 0 0 0 rgb(61 232 123 / 70%);
  animation: ${pulse} 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  text-align: center;
  line-height: 60px;
  font-size: 44px;
  border-radius: 30px;
  z-index: 9999999;

  &:hover {
    animation: none;
  }

  .icon-whats {
    color: #fff;
    display: block;
    width: 100%;
    height: 100%;
    line-height: 60px;
  }


`;
