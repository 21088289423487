import React, { useState } from 'react';
import { FAQContainer, Question, Answer, FAQItem } from './styles';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    {
      question: 'O que é a ATOvenda?',
      answer:
        'A ATOvenda é uma plataforma de atendimento automatizado via WhatsApp, que integra múltiplos números em um único painel, oferecendo respostas rápidas e automáticas.'
    },
    {
      question: 'Como funciona a automação de atendimento?',
      answer:
        'A automação é configurada com base nas necessidades da sua empresa, permitindo que as mensagens sejam respondidas de forma automática e personalizada, 24 horas por dia.'
    },
    {
      question: 'Quais são os principais benefícios de usar a ATOvenda?',
      answer:
        'Ganhamos tempo ao automatizar respostas, aumentamos a satisfação do cliente com um atendimento eficiente e ajudamos na redução de custos com pessoal.'
    },
    {
      question: 'Como a ATOvenda se diferencia de outras plataformas?',
      answer:
        'Além da automação e unificação de números de WhatsApp, oferecemos um suporte especializado que cuida de toda a implementação e personalização do sistema, sem que você precise se preocupar com a parte técnica.'
    },
    {
      question: 'Preciso de conhecimento técnico para usar a plataforma?',
      answer:
        'Não! Nossa equipe cuida de toda a configuração e deixa o sistema pronto para uso. Após a implantação, oferecemos suporte contínuo para qualquer dúvida que possa surgir.'
    }
  ];

  return (
    <FAQContainer>
      <h1>Perguntas frequentes (FAQ)</h1>
      <p>Perguntas Frequentes sobre a ATOvenda</p>
      {faqData.map((faq, index) => (
        <FAQItem key={index}>
          <Question onClick={() => toggleFAQ(index)} isOpen={openIndex === index}>
            {faq.question}
          </Question>
          <Answer isOpen={openIndex === index}>{faq.answer}</Answer> {/* Aqui é onde passamos isOpen */}
        </FAQItem>
      ))}
    </FAQContainer>
  );
};

export default FAQ;
