import './styles';
import LogoMarca from '../../../assets/whiteLogo.png';
import { DivTransform, FooterColumnFour, FooterColumnOne, FooterColumnThree, FooterColumnTwo, FooterContainer, Icons, LargeText, SmallText, TextFooter } from './styles';
import { FacebookLogo, InstagramLogo } from '@phosphor-icons/react';
export default function Footer(): JSX.Element {
  
  return (
    <div style={{display: "flex",justifyContent: "center",backgroundColor: "#031323"}}>
        <FooterContainer>
            <FooterColumnOne>
                <div><img src={LogoMarca} alt="Logo" /></div>
                <LargeText>Nascemos para revolucionar o atendimento ao cliente, oferecendo soluções automatizadas, focadas em otimizar processos e aumentar a satisfação do cliente.</LargeText>
                <SmallText>© Copyright 2024・ATO VENDA・All rights reserved・CNPJ: 41.512.848/0001-87</SmallText>
            </FooterColumnOne>
            <DivTransform>
                <FooterColumnTwo>
                    <TextFooter style={{marginBottom: "8px"}}>Páginas</TextFooter>
                    <a href="#home"><TextFooter className='TextFooterStyle'>Home</TextFooter></a>
                    <a href="#beneficios"><TextFooter className='TextFooterStyle'>Benefícios</TextFooter></a>
                    <a href="#planos"><TextFooter className='TextFooterStyle'>Planos</TextFooter></a>
                    <a href="#depoimentos"><TextFooter className='TextFooterStyle'>Depoimentos</TextFooter></a>
                </FooterColumnTwo>
                <FooterColumnThree>
                    <TextFooter style={{marginBottom: "8px"}}>Contato</TextFooter>
                    <a href="https://wa.me/554498069716?text=Gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20ATOvenda" target="_blank" rel="noopener noreferrer"><TextFooter className='TextFooterStyle'>Fale Conosco</TextFooter></a>
                </FooterColumnThree> 
            </DivTransform>
            <FooterColumnFour style={{alignItems: "flex-end", marginRight: "20px"}}>
                <Icons>
                    <div><a href="https://www.facebook.com/atovenda" target="_blank" rel="noopener noreferrer"><FacebookLogo size={32} weight="fill" color="#ffffff"/></a></div>
                    <div><a href="https://www.instagram.com/atovendaoficial" target="_blank" rel="noopener noreferrer"><InstagramLogo size={32} weight="fill" color="#ffffff" /></a></div>
                </Icons>
            </FooterColumnFour>
            <SmallText className='HiddenFooter'>© Copyright 2024・ATO VENDA・All rights reserved・CNPJ: 41.512.848/0001-87</SmallText>
        </FooterContainer>
    </div>
  );
}