import React from 'react';
import { Robot, ClockUser, ListDashes, UsersFour,User } from '@phosphor-icons/react';
import { CardAuto, CardAutoImage, CardAutoText, CardAutoTextContainer, TextBlock, TextFour, TitleFour } from '../../screens/Home/styles';
import agendamento from '../../assets/Agendamento.png';
import atendimento from '../../assets/Conversa.png'
import monitoracao from '../../assets/monitor.png'

interface CardGenericoProps {
  index: number; 
}

const CardGenerico: React.FC<CardGenericoProps> = ({ index }) => {
  const cardContent = [
    {
      image:    agendamento, 
      title1: 'Atendimento Automático com IA',
      text1: 'Nosso bot responde a cada cliente imediatamente, agilizando processos e atendendo solicitações específicas, garantindo uma interação eficiente e uma experiência digital única.',
      icon1: <Robot size={32}/>,
      title2: 'Mensagens Programadas com Disparo',
      text2: 'O ChatBot funciona 24h, desta forma programe e envie mensagens em massa para a sua base de clientes no momento ideal.',
      icon2: <ClockUser size={30}/>,
    },
    {
      image:    atendimento, 
      title1: 'Atendimento Humano e Personalizado',
      text1: 'Automatize o envio de mensagens mantendo um toque personalizado, para proporcionar uma experiência mais envolvente e aumentar a satisfação do cliente.',
      icon1: <User size={32}/>,
      title2: 'Protocolo de Atendimento e Avaliação',
      text2: 'Crie protocolos personalizados e colete feedbacks automáticos após cada atendimento, garantindo melhorias contínuas e insights valiosos para seus serviços.',
      icon2: <ClockUser size={30}/>,
    },
    {
      image:    monitoracao,
      title1: 'Painel de Controle Completo',
      text1: 'Monitore e otimize seus atendimentos via WhatsApp com métricas detalhadas, gráficos interativos e relatórios em tempo real, melhorando a eficiência e a qualidade do serviço.',
      icon1: <ListDashes size={32}/>,
      title2: 'Gerenciamento de Equipe e Tarefas',
      text2: 'Organize seu time e distribua atendimentos de forma simples e eficiente através de um painel de controle completo, garantindo uma gestão otimizada e resultados aprimorados.',
      icon2: <UsersFour size={30}/>,
    }
  ];

  const { image, title1, text1, icon1, title2, text2, icon2 } = cardContent[index] || {};

  return (
    <CardAuto>
      <CardAutoImage src={image} alt="Imagem no CardAuto" />
      <CardAutoTextContainer>
        <CardAutoText style={{ margin: "40px 20px" }}>
          <TextBlock>
            {icon1}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TitleFour>{title1}</TitleFour>
              <TextFour>{text1}</TextFour>
            </div>
          </TextBlock>
        </CardAutoText>
        <CardAutoText style={{ margin: "10px 20px 80px 20px" }}>
          <TextBlock>
            {icon2}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TitleFour>{title2}</TitleFour>
              <TextFour>{text2}</TextFour>
            </div>
          </TextBlock>
        </CardAutoText>
      </CardAutoTextContainer>
    </CardAuto>
  );
};

export default CardGenerico;
