import React, { useEffect, useRef, useState } from 'react';
import { Button, CardLeft, CardRight, SectionOne, TextOne, Title, Image, DivAll, SecondImage, SecondImageMob, SecondCardLeft , SecondCardRight, CardContainer, SecondTitle, SecondButton, SecondText, SectionTwo, SectionThree, HeaderText, DivMid, SectionGrids, LeftGrid, LargeTextDiv, MediumTextDiv, SmallTextDiv, RightGrid, CardText, IconGrid, CardGrid, DivGrids, LeftTexts, IconGridContainer, CheckStyle, CustomSection, IntroDiv, SingleColumn, CenteredText, ColumnIcon, DualTextContainer, LeftTextGroup, CustomButton, ActionButtonsDiv, DivClient, CenteredTextOne, LogoEnd, CenteredClient, SectionSeven, ContentSeven, TextSeven, TextSmallSeven, ButtonSeven, DivSeven, ImageSeven, BottomDiv, SectionPlans, TextPlans, TopDiv, ColumnPlans, TextTitlePlans, ButtonPlans, TextAll, Label, Value, ContentRed, BackgroundStyle, DivClick, DivMove, ContainerOne, RightGridHidden, CarouselContainer, Slide, DivMargin, Aspas, ChatBubble, DivChat, FlexCheck, FlexUm } from './styles';
import { ChatCenteredText, Check, Clock, Network, Star} from '@phosphor-icons/react';
import RightFinal from '../../assets/rightFinal.png';
import imageLeft from '../../assets/chat.png';
import imageLeftMobile from '../../assets/chat-mobile.png';
import imageMan from '../../assets/imageLeft.png';
import imageManMob from '../../assets/ImageRightTicket.png';
import serpudo from '../../assets/serpudo.png';
import setecard from '../../assets/7card f.png';
import arpe from '../../assets/Arpe.png';
import barber from '../../assets/Barber.png';
import logoWhatsapp from '../../assets/logoWhatsapp.png';
import logoTelegram from '../../assets/logoTelegram.png';
import meta from '../../assets/meta.webp';
import games from '../../assets/games.png';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import CardGenerico from '../../component/CardGenerico';
import FAQ from '../../component/Questions';

export default function Home(): JSX.Element {

  const [activeText, setActiveText] = useState(0);
  const [selectedBlock, setSelectedBlock] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [activeBubble, setActiveBubble] = useState<number>(0);

  
  const handleBubbleClick = (index: number) => {
    setActiveBubble(index);
  };

  const handleClickBackground = (index: number) => {
    setStart(selectedBlock * 33.33);
    setEnd(index * 33.33);
    setSelectedBlock(index);
  };

  

  const slideData = [
    {
      icon: <Clock color='white' style={{ width: "32px", height: "32px" }}/>,
      text: "Melhore a eficiência do atendimento e aumente a precisão nas respostas aos clientes."
    },
    {
      icon: <Star color='white' style={{ width: "32px", height: "32px" }}/>,
      text: "Nossa equipe configura tudo para você, garantindo que o sistema esteja pronto para uso imediato."
    },
    {
      icon: <Network color='white' style={{ width: "32px", height: "32px" }}/>,
      text: "Integre a ATOvenda a ferramentas e CRMs para uma gestão de atendimento melhor."
    },
    {
      icon: <ChatCenteredText color='white' style={{ width: "32px", height: "32px" }}/>,
      text: "Automatize o processo de vendas, desde o primeiro contato até o fechamento final."
    }
  ];  

  const plansData = [
    {
      title: "Basic +",
      price: "R$177,<span>95</span>",
      value: "valor mensal",
      url: "https://pag.ae/7-V-J_MTG",
      features: [
        "1 Número de WhatsApp",
        "1 Administrador",
        "1 Atendente",
        "Uso de Inteligência Artificial",
        "Mensagens ilimitadas",
        "Geração de protocolo por atendimento",
        "Dashboard completa",
        "Gerenciamento de departamentos",
        "Atendimentos ilimitados"
      ],
      extraInfo: [
        "SEM CUSTOS ADICIONAIS",
        "Sem fidelidade ou multa de cancelamento.",
        "Não cobramos nenhuma taxa adicional por mensagens automáticas enviadas."
      ],
      label: null
    },
    {
      title: "Acelera +",
      price: "R$277,<span>95</span>",
      value: "valor mensal",
      url: "https://pag.ae/7-V-KUScG",
      features: [
        "Plano Basic +",
        "2 Números de WhatsApp",
        "3 Atendentes",
        "1 Supervisor",

      ],
      extraInfo: [
        "SEM CUSTOS ADICIONAIS",
        "Sem fidelidade ou multa de cancelamento.",
        "Não cobramos nenhuma taxa adicional por mensagens automáticas enviadas."
      ],
      label: "MAIS VENDIDO"
    },
    {
      title: "Master +",
      price: "R$437,<span>95</span>",
      value: "valor mensal",
      url: "https://pag.ae/7-V-Lk4r2",
      features: [
        "Plano Acelera+",
        "5 Números de WhatsApp",
        "10 Atendentes",
        "3 Supervisor"
      ],
      extraInfo: [
        "SEM CUSTOS ADICIONAIS",
        "Sem fidelidade ou multa de cancelamento.",
        "Não cobramos nenhuma taxa adicional por mensagens automáticas enviadas."
      ],
      label: null
    }
  ];

  const slides = [
    {
      text: "Estamos encantados com a plataforma de gestão de atendimentos nas redes sociais! A automação via chatbot tem sido uma verdadeira revolução na empresa.",
      client: "Gabriel Serpa",
      position: "Serpudo",
      image: serpudo
    },
    {
      text: "A plataforma de atendimento com chatbot automatizado transformou nossas interações nas redes sociais! Respostas rápidas e eficientes, estamos muito satisfeitos!",
      client: "Alexandra Muniz",
      position: "Rushar Games",
      image: games
    },
    {
      text: "A plataforma de gestão de atendimentos superou nossas expectativas! A automação com chatbot otimizou processos e aumentou a eficiência da equipe.",
      client: "Romeu Ferrari",
      position: "7Card",
      image: setecard
    },
    {
      text: "As inteligências artificiais no atendimento têm otimizado nosso suporte, garantindo respostas rápidas e uma experiência excepcional para os clientes.",
      client: "Dr. José Leozir",
      position: "Clínica Arpe",
      image: arpe
    },
    {
      text: "O sistema de automação agilizou os agendamentos e respostas, melhorando a organização e a satisfação dos clientes!",
      client: "William Santos",
      position: "La Barber Barbearia",
      image: barber
    }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    
  };

  const settingsTwo = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const settingsThree = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      }
    ]
  };
 

  return (
    <DivAll>
      <ContainerOne id='home'>
        <SectionOne>
          <CardLeft>
            <Title>Ganhe <span>Tempo</span> e <span>Eficiência</span> com a Automação do Atendimento da sua Empresa</Title>
            <TextOne>Ganhe tempo com automação inteligente e chatbots humanizados. Aumente a eficiência, a satisfação dos seus clientes e foque no crescimento do seu negócio.</TextOne>
            <FlexCheck>
            <div style={{ display: "flex", alignItems: "flex-start", marginTop: "12px", gap: "6px" }}>
              <Check style={{ position: "relative", top: "2px" }} size={16} weight="bold" color='#1FD23D' />
              <p style={{ textAlign: "left"}}>Simplifique o atendimento da sua empresa</p>
            </div>
            <div style={{ display: "flex", alignItems: "flex-start", marginTop: "12px", gap: "6px"  }}>
              <Check style={{ position: "relative", top: "2px" }} size={16} weight="bold" color='#1FD23D' />
              <p style={{ textAlign: "left"}}>Aumente a satisfação dos seus clientes agora</p>
            </div>
            <div style={{ display: "flex", alignItems: "flex-start", marginTop: "12px", gap: "6px"  }}>
              <Check style={{ position: "relative", top: "2px" }} size={16} weight="bold" color='#1FD23D' />
              <p style={{ textAlign: "left"}}>Utilize a API Oficial da Meta</p>
            </div>
            
            </FlexCheck>
            
            <FlexUm>
              <a href='#planos'><Button>Comece Agora</Button></a>
              <Image className='seloWhatsapp' src={meta} alt="Imagem grande" />
            </FlexUm>
          </CardLeft>
          <CardRight>
            <Image className='logoWhatsapp' src={logoWhatsapp}/>
            <Image className='logoTelegram' src={logoTelegram}/>
            <Image className='rightFinal' src={RightFinal} alt="Imagem grande" loading="lazy"/>
          </CardRight>
        </SectionOne>
      </ContainerOne>

      <CardContainer> 

        <SectionTwo>
          <SecondCardLeft>
            <SecondImage src={imageLeft} alt="Imagem Chat IA" />
            <SecondImageMob src={imageLeftMobile} alt="Imagem Chat IA" />
          </SecondCardLeft>
          <SecondCardRight>
            <SecondTitle className='hiddenTitle'>Experimente a <span> inteligência artificial </span> trabalhando para você</SecondTitle>
            <SecondText>Experimente a revolução no atendimento com nosso chatbot de IA! Respostas rápidas e precisas 24/7, com análise inteligente que entende e antecipa as necessidades dos seus clientes. Aumente a eficiência e impulsione suas vendas.</SecondText>
              <SecondButton> <a href="https://wa.me/554498069716?text=Gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20ATOvenda" target="_blank" rel="noopener noreferrer"> Fale com nosso Especialista</a> </SecondButton> 
          </SecondCardRight>
        </SectionTwo>
      </CardContainer>

    <DivMid id='beneficios'>
      <SectionThree>
        <HeaderText>Descubra os benefícios que um atendimento eficiente e inteligente pode proporcionar</HeaderText>
        <DivMove>
          <BackgroundStyle start={start} end={end} />
          {['Automação', 'Atendimento', 'Monitoração'].map((text, index) => (
            <DivClick key={index} onClick={() => handleClickBackground(index)}>
              <a className={selectedBlock === index ? 'active' : ''}>{text}</a>
            </DivClick>
          ))}
        </DivMove>
        <DivChat>
          {['Automação', 'Atendimento', 'Monitoração'].map((text, index) => (
            <ChatBubble
              key={index}
              clicked={selectedBlock === index}
              onClick={() => handleClickBackground(index)}
            >
              {text}
            </ChatBubble>
          ))}
        </DivChat>
      </SectionThree>

      <CardGenerico index={selectedBlock} />
    </DivMid>
    

      <DivGrids>
        <p>Soluções Poderosas com </p>
        <a>Automação de WhatsApp</a>
        <SectionGrids>
          <LeftGrid>
            <LargeTextDiv>
              Aumente seu poder de vendas através de mensagens instantâneas.
            </LargeTextDiv>
            <MediumTextDiv>
            Utilize <span>múltiplos números de WhatsApp</span> com vários atendentes para um atendimento mais rápido e eficaz. Ofereça respostas imediatas e soluções ágeis.
            </MediumTextDiv>
            <SmallTextDiv>
              <LeftTexts>
                <Check style={{ position: "relative", top: "2px" }} size={16} weight="bold" color='#1FD23D' />
                <a>Serviço de atendimento completo</a>
              </LeftTexts>
              <LeftTexts className='newPadding'>
                <Check style={{ position: "relative", top: "2px" }} size={16} weight="bold" color='#1FD23D' />
                <a>Vários atendentes</a>
              </LeftTexts>
            </SmallTextDiv>
            <SmallTextDiv>
              <LeftTexts>
                <Check style={{ position: "relative", top: "2px" }} size={16} weight="bold" color='#1FD23D' />
                <a>Automação sem complicações</a>
              </LeftTexts>
              <LeftTexts className='newPadding'>
                <Check style={{ position: "relative", top: "2px" }} size={16} weight="bold" color='#1FD23D' />
                <a>Mensagens Rápidas</a>
              </LeftTexts>
            </SmallTextDiv>
          </LeftGrid>

          <RightGridHidden>
            <CarouselContainer>
              <Slider {...settings}>
                {slideData.map((slide, index) => (
                  <Slide key={index}>
                    <CardGrid>
                      <IconGridContainer>
                        <IconGrid>
                          <CheckStyle style={{ position: "absolute", right: "-14px", top: "8px"}}><Check color='white'/></CheckStyle>
                          {slide.icon}
                        </IconGrid>
                      </IconGridContainer>
                      <CardText><a>{slide.text}</a></CardText>
                    </CardGrid>
                  </Slide>
                ))}
              </Slider>
            </CarouselContainer>
          </RightGridHidden>

          <RightGrid>
            {[0, 1].map(row => (
              <div key={row} style={{display: "flex", flexDirection: "row", gap: "20px"}}>
                {slideData.slice(row * 2, row * 2 + 2).map((card, index) => (
                  <CardGrid key={index}>
                    <IconGridContainer>
                      <IconGrid>
                        <CheckStyle style={{ position: "absolute", right: "-14px", top: "8px"}}><Check color='white'/></CheckStyle>
                        {card.icon}
                      </IconGrid>
                    </IconGridContainer>
                    <CardText><a>{card.text}</a></CardText>
                  </CardGrid>
                ))}
              </div>
            ))}
          </RightGrid>
        </SectionGrids> 
      </DivGrids>

      <SectionPlans id='planos'>
  <TopDiv>
    <TextAll>
      <a>Encontre o plano perfeito para </a>
      impulsionar as vendas da sua empresa.
    </TextAll>
  </TopDiv>

  <BottomDiv className='Visible'>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {plansData.map((plan, index) => (
        <ColumnPlans key={index} className={plan.label ? 'More' : ''}>
          {plan.label && <Label>{plan.label}</Label>}
          <TextTitlePlans>{plan.title}</TextTitlePlans>
          <TextTitlePlans className='style' dangerouslySetInnerHTML={{__html: plan.price}} />
          <Value>{plan.value}</Value>
          <ButtonPlans as="a" href={plan.url}>Conhecer Plano</ButtonPlans>
          <div style={{ marginInline: "20px" }}>
            {plan.features.map((feature, i) => (
              <TextPlans key={i}><Check size={12} weight='bold' color='#3C3C3C' style={{ marginRight: "6px" }}/>{feature}</TextPlans>
            ))}
          </div>
          <ContentRed>{plan.extraInfo[0]}</ContentRed>
          <TextPlans className='textplan' style={{ marginTop: "12px" }}>{plan.extraInfo[1]}</TextPlans>
          <TextPlans className='textplan' style={{ marginBottom: "40px", marginTop: "10px" }}>{plan.extraInfo[2]}</TextPlans>
        </ColumnPlans>
      ))}
    </div>
  </BottomDiv>
  <BottomDiv className='Hidden'>
    <CarouselContainer className='CarouselFour'>
      <Slider {...settingsTwo}>
        {plansData.map((plan, index) => (
          <Slide key={index} className='CarouselFour'>
            <ColumnPlans className={plan.label ? 'More CarouselTwo' : 'CarouselFour'}>
              {plan.label && <Label>{plan.label}</Label>}
              <TextTitlePlans>{plan.title}</TextTitlePlans>
              <TextTitlePlans className='style' dangerouslySetInnerHTML={{__html: plan.price}} />
              <Value>{plan.value}</Value>
              <ButtonPlans as="a" href={plan.url}>Conhecer Plano</ButtonPlans>
              <div style={{ marginInline: "20px" }}>
                {plan.features.map((feature, i) => (
                  <TextPlans key={i}><Check size={12} weight='bold' color='#3C3C3C' style={{ marginRight: "6px" }}/>{feature}</TextPlans>
                ))}
              </div>
              <ContentRed>{plan.extraInfo[0]}</ContentRed>
              <TextPlans className='textplan' style={{ marginTop: "12px" }}>{plan.extraInfo[1]}</TextPlans>
              <TextPlans className='textplan' style={{ marginBottom: "40px", marginTop: "10px" }}>{plan.extraInfo[2]}</TextPlans>
            </ColumnPlans>
          </Slide>
        ))}
      </Slider>
    </CarouselContainer>
  </BottomDiv>
</SectionPlans>

      <SectionSeven>
        <DivSeven>
          <ContentSeven>
            <TextSeven>Já decidiu se quer <span>automatizar</span> o seu atedimento e <span>aumentar</span> o ticket da sua empresa? </TextSeven>
            <ImageSeven className='HiddenImage' src={imageManMob} alt="Descrição da Imagem" />
            <TextSmallSeven>Muitos clientes da Ato Venda, em diversos setores específicos, estão usando nossos produtos para se engajar com os clientes e alcançar seus objetivos de negócios.</TextSmallSeven>
            <a href='#planos'><ButtonSeven >Venda Mais Agora</ButtonSeven></a>
          </ContentSeven>
          <ImageSeven src={imageMan} alt="Descrição da Imagem" />
        </DivSeven>
      </SectionSeven>

      <FAQ/>

      <DivClient id='depoimentos'>
        <CustomSection>
          <IntroDiv>
            <CenteredTextOne>
              Veja o que os nossos clientes <span>falam da Ato Venda:</span>
            </CenteredTextOne>
          </IntroDiv>
          <div style={{display: "flex", justifyContent: "center",alignItems: "center"}}>
            <CarouselContainer className='CarouselThree'>
              <Slider {...settingsThree}>
                {slides.map((slide, index) => (
                  <Slide className='CarouselThree' key={index}>
                    <SingleColumn>
                      <DivMargin>
                        <LogoEnd><Aspas>“</Aspas></LogoEnd>
                        <CenteredText>{slide.text}</CenteredText>
                        <DualTextContainer>
                          <LeftTextGroup>
                            <CenteredClient className='first'>{slide.client}</CenteredClient>
                            <CenteredClient className='second'>{slide.position}</CenteredClient>
                          </LeftTextGroup>
                          <ColumnIcon><img src={slide.image} alt="" /></ColumnIcon>
                        </DualTextContainer>
                      </DivMargin>
                    </SingleColumn>
                  </Slide>
                ))}
              </Slider>
            </CarouselContainer>
          </div>
        </CustomSection>
      </DivClient>
    </DivAll>
  );
}
