import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../src/screens/Home';
import Navbar from './component/organisms/Navbar';
import Footer from './component/organisms/Footer';
import ScrollToTopButton from './component/ButtonSeta'; 
import WhatsAppButton from './component/ButtonWhatsApp';
import './App.css';


const App: React.FC = () => {
  return (
    <div className="App">
      <Navbar></Navbar>      
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </main>
      <ScrollToTopButton />
      <WhatsAppButton />
      <Footer></Footer>
    </div>
  );
};

export default App;
